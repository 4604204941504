import { render, staticRenderFns } from "./SettingsView.vue?vue&type=template&id=524b18ae&"
import script from "./SettingsView.vue?vue&type=script&lang=js&"
export * from "./SettingsView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports