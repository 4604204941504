import { render, staticRenderFns } from "./PlayerPointsSeasons.vue?vue&type=template&id=4ab140a8&scoped=true&"
import script from "./PlayerPointsSeasons.vue?vue&type=script&lang=js&"
export * from "./PlayerPointsSeasons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ab140a8",
  null
  
)

export default component.exports